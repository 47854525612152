export default {
    lang: {
        zh_tw: "繁体中文",
        zh_cn: "简体中文",
        en_us: "英文",
    },
    yes: "是",
    no: "否",
    name: "姓名",
    delete: "删除",
    player_name: "玩家",
    age: "年龄",
    sex: "性别",
    none: "无",
    select_image: "选择图片",
    upload_image: "上传图片",
    cancel_image: "取消图片",
    info: "一般",
    background: "背景",
    skill: "技能",
    skill_equip: "技能与装备",
    weapon_equip: "武器与装备",
    spell: "法术",
    note: "笔记",
    option: "选项",
    viewPermission:"检视权限",
    allPeople:"所有人",
    sessionPlayer:"团务所有人",
    sessionGM:"限团务GM",
    copyLink:"複製角色卡连结",
    downloadJSON:"下载为JSON",
    coc7th: {
        char_info: "调查员基本资料：",
        char_status: "调查员状态：",
        char_equip: "调查员装备：",
        char_image: "调查员形象：",
        char_background: "调查员背景：",
        char_experience: "调查员经历：",
        char_skill: "调查员技能",
        class: "职业",
        birthplace: "出生地",
        residence: "现居地",
        db_build: "伤害加成与体格(DB & Build)",
        mov: "机动力(MOV)",
        injured_status: "负伤状态",
        injured1: "重伤",
        injured2: "濒死",
        injured3: "暂时稳定",
        insane_status: "疯狂状态",
        insane1: "临时疯狂",
        insane2: "不定性疯狂",
        insane3: "潜在疯狂",
        money: "金钱",
        weapon: "武器",
        equip: "携带物品",
        injuries: "创伤与伤疤",
        encounter: "第三类接触",
        mania: "恐惧与狂热",
        magic: "魔法书与咒文",
        myth: "神话相信者",
        role_description: "形象描述",
        description: "个人描述",
        belief: "思念与信念",
        sig_people: "重要之人",
        mean_location: "意义非凡之地",
        treasured_possession: "宝贵之物",
        trait: "特点",
        class_feature: "职业特徵",
        class_point: "职业：",
        interest_point: "兴趣：",
        skill_name: "名称",
        skill_default: "初始",
        skill_interest: "兴趣",
        skill_custom: "自定义",
        skill_class: "职业",
        skill_grow: "成长",
        skill_total: "总值",
        "Accounting": "会计",
        "Anthropology": "人类学",
        "Appraise": "估价",
        "Archaeology": "考古学",
        "Charm": "魅惑",
        "Climb": "攀爬",
        "Computer Use": "电脑使用",
        "Credit Rating": "信用评级",
        "Cthulhu Mythos": "克苏鲁神话",
        "Disguise": "乔装",
        "Dodge": "闪避",
        "Drive Auto": "自动车驾驶",
        "Electrical Repair": "电器维修",
        "Electronics": "电子学",
        "Fast-Talk": "话术",
        "First Aid": "急救",
        "History": "历史",
        "Intimidate": "威吓",
        "Jump": "跳跃",
        "Law": "法律",
        "Library Use": "图书馆使用",
        "Listen": "聆听",
        "Locksmith": "锁匠",
        "Mechanical Repair": "机器维修",
        "Medicine": "医学",
        "Natural World": "自然学",
        "Navigate": "导航",
        "Occult": "神祕学",
        "Operate Heavy Machinery": "操纵重型机器",
        "Persuade": "说服",
        "Psychoanalysis": "心理分析",
        "Psychology": "心理学",
        "Ride": "骑术",
        "Sleight of Hand": "巧手",
        "Spot Hidden": "侦查",
        "Stealth": "隐密行动",
        "Swim": "游泳",
        "Throw": "投掷",
        "Track": "追踪",
        "Animal Handling": "动物驯养",
        "Artillery": "炮术",
        "Demolitions": "爆破",
        "Diving": "潜水",
        "Hypnosis": "催眠",
        "Read Lips": "读唇",
        "Pilot": "专业驾驶",
        "Survival": "求生",
        "Art and Craft": "艺术与工艺",
        "Fighting": "斗殴",
        "Firearms": "火器",
        "Language (Other)": "他国语言",
        "Language (Own)": "母语",
        "Science": "科学",
        "Lore": "偏门学问",
    },
    dnd5e: {
        char_info: "冒险者基本资料：",
        char_status: "冒险者状态：",
        char_image: "冒险者形象：",
        char_skill: "冒险者技能：",
        char_equip: "冒险者装备：",
        char_appearance: "冒险者外观：",
        char_trait: "冒险者特质：",
        char_background: "冒险者背景：",
        hint: {
            class: {
                bard: "吟游诗人(Bard)",
                barbarian: "野蛮人(Barbarian)",
                cleric: "牧师(Cleric)",
                druid: "德鲁伊(Druid)",
                fighter: "战士(Fighter)",
                monk: "武僧(Monk)",
                paladin: "圣骑士(Paladin)",
                ranger: "游侠(Ranger)",
                rogue: "盗贼(Rogue)",
                sorcerer: "术士(Sorcerer)",
                warlock: "契术师(Warlock)",
                wizard: "法师(Wizard)"
            },
            faction: {
                lawful_good: "守序善良",
                neutral_good: "中立善良",
                chaotic_good: "混乱善良",
                lawful_neutral: "守序中立",
                true_neutral: "绝对中立",
                chaotic_neutral: "混乱中立",
                lawful_evil: "守序邪恶",
                neutral_evil: "中立邪恶",
                chaotic_evil: "混乱邪恶"
            }
        },
        str: "力量",
        dex: "敏捷",
        con: "体魄",
        int: "智慧",
        wis: "感知",
        cha: "魅力",
        level: "等级",
        exp: "经验",
        faction: "阵营",
        race: "种族",
        class: "职业",
        background: "背景",
        armorValue: "护甲值",
        initiative: "先攻值",
        speed: "速度",
        hp: "临时生命/生命值/最大生命",
        hit_dice: "生命骰/总生命骰",
        success: "成功",
        fail: "失败",
        death_save: "死亡豁免",
        attack_spellcast: "攻击&法术",
        attack: "攻击",
        spellcast: "法术",
        passive_wisdom: "被动感知",
        inspiration: "激励值",
        pro: "熟练加成",
        savings: "豁免检定",
        skills: "技能",
        equipment: "装备",
        treasure: "宝物",
        language: "其他专长&语言",
        height: "身高",
        skin: "肤色",
        pupil: "瞳孔颜色",
        weight: "体重",
        hair: "发色",
        trait: "特徵",
        otherTrait: "其他特徵&特性",
        personality: "性格",
        ideals: "理念",
        bonds: "关係",
        flaws: "缺点",
        backstory: "背景历史",
        alignment: "同盟&组织",
        spell_class: "法术职业",
        spell_ability: "法术施展能力",
        spell_save: "法术豁免值",
        spell_bonus: "法术攻击加值",
        cantrips: "小法术",
        spell_total: "总数",
        spell_expanded: "用量",
        list_append: "增加",
        Acrobatics: "特技动作",
        "Animal Handling": "驯服动物",
        Arcana: "奥术",
        Athletics: "运动",
        Deception: "欺瞒",
        History: "历史",
        Insight: "察言观色",
        Intimidation: "恐吓",
        Investigation: "调查",
        Medicine: "医术",
        Nature: "自然",
        Perception: "观察",
        Performance: "表演",
        Persuasion: "说服",
        Religion: "宗教",
        "Sleight of Hand": "扒手",
        Stealth: "隐藏",
        Survival: "求生",


    },
    coc6th: {
        char_info: "调查员基本资料：",
        char_status: "调查员状态：",
        char_equip: "调查员装备：",
        char_image: "调查员形象：",
        char_background: "调查员背景：",
        char_data: "调查员资料：",
        char_skill: "调查员技能：",
        magic_tomes: "魔法与传闻：",
        class: "职业",
        degree: "学历",
        birthplace: "出生地",
        residence: "现居地",
        db_build: "伤害加成(DB)",
        idea: "灵感(IDEA)",
        know: "知识(KNOW)",
        luk: "幸运(LUCK)",
        mental: "疯狂程度",
        insanity: "精神疾病",
        money: "资产",
        weapon: "武器",
        equip: "携带物品",
        injuries: "创伤与伤口",
        scars: "伤痕与伤疤",
        encounter: "第三类接触",
        tomes: "阅读过的典籍",
        magic: "已知咒文与神器",
        role_description: "形象描述",
        description: "背景故事",
        family: "家人与朋友",
        trait: "特点",
        class_point: "职业：",
        interest_point: "兴趣：",
        skill_name: "名称",
        skill_default: "初始",
        skill_interest: "兴趣",
        skill_custom: "自定义",
        skill_class: "职业",
        skill_grow: "成长",
        skill_total: "总值",
        "Accounting": "会计",
        "Anthropology": "人类学",
        "Archaeology": "考古学",
        "Astronomy": "天文学",
        "Bargain": "议价",
        "Biology": "生物学",
        "Chemistry": "化学",
        "Climb": "攀爬",
        "Computer Use": "电脑使用",
        "Conceal": "藏匿",
        "Credit Rating": "信用评级",
        "Cthulhu Mythos": "克苏鲁神话",
        "Disguise": "乔装",
        "Dodge": "闪避",
        "Drive Auto": "自动车驾驶",
        "Electrical Repair": "电器维修",
        "Electronics": "电子学",
        "Fast-Talk": "话术",
        "First Aid": "急救",
        "Firearm":"火器",
        "Geology": "地理学",
        "History": "历史",
        "Jump": "跳跃",
        "Law": "法律",
        "Library Use": "图书馆使用",
        "Listen": "聆听",
        "Locksmith": "锁匠",
        "Mechanical Repair": "机器维修",
        "Medicine": "医学",
        "Natural World": "自然学",
        "Navigate": "导航",
        "Occult": "神祕学",
        "Operate Heavy Machinery": "操纵重型机器",
        "Persuade": "说服",
        "Pharmacy": "药学",
        "Photography": "摄影",
        "Physics": "物理",
        "Psychoanalysis": "心理分析",
        "Psychology": "心理学",
        "Ride": "骑术",
        "Sleight of Hand": "巧手",
        "Spot Hidden": "侦查",
        "Stealth": "隐密行动",
        "Swim": "游泳",
        "Throw": "投掷",
        "Track": "追踪",
        "Handgun": "手枪",
        "Machine Gun": "机关枪",
        "Rifle": "步枪",
        "Shotgun": "霰弹枪",
        "SMG": "冲锋枪",
        "Hypnosis": "催眠",
        "Read Lips": "读唇",
        "Pilot": "专业驾驶",
        "Pilot(Car)": "驾驶汽车",
        "Pilot(Carriage)": "驾驶马车",
        "Art and Craft": "艺术与工艺",
        "Fighting": "斗殴",
        "Weapon": "武器",
        "Language (Other)": "他国语言",
        "Language (Own)": "母语",
        "Science": "科学",
        "Lore": "偏门学问",
        "Fist": "拳头",
        "Kick": "踢击",
        "Grapple": "擒拿",
        "Head": "头槌"
    },

}
