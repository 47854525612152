export default {
    lang: {
        zh_tw: "繁體中文",
        zh_cn: "簡體中文",
        en_us: "英文",
    },
    yes: "是",
    no: "否",
    name: "姓名",
    delete: "刪除",
    player_name: "玩家",
    age: "年齡",
    sex: "性別",
    none: "無",
    select_image: "選擇圖片",
    upload_image: "上傳圖片",
    cancel_image: "取消圖片",
    info: "一般",
    background: "背景",
    skill: "技能",
    skill_equip: "技能與裝備",
    weapon_equip: "武器與裝備",
    spell: "法術",
    note: "筆記",
    option: "選項",
    viewPermission:"檢視權限",
    allPeople:"所有人",
    sessionPlayer:"團務所有人",
    sessionGM:"限團務GM",
    copyLink:"複製角色卡連結",
    downloadJSON:"下載為JSON",
    coc7th: {
        char_info: "調查員基本資料：",
        char_status: "調查員狀態：",
        char_equip: "調查員裝備：",
        char_image: "調查員形象：",
        char_background: "調查員背景：",
        char_experience: "調查員經歷：",
        char_skill: "調查員技能",
        class: "職業",
        birthplace: "出生地",
        residence: "現居地",
        db_build: "傷害加成與體格(DB & Build)",
        mov: "機動力(MOV)",
        injured_status: "負傷狀態",
        injured1: "重傷",
        injured2: "瀕死",
        injured3: "暫時穩定",
        insane_status: "瘋狂狀態",
        insane1: "臨時瘋狂",
        insane2: "不定性瘋狂",
        insane3: "潛在瘋狂",
        money: "金錢",
        weapon: "武器",
        equip: "攜帶物品",
        injuries: "創傷與傷疤",
        encounter: "第三類接觸",
        mania: "恐懼與狂熱",
        magic: "魔法書與咒文",
        myth: "神話相信者",
        role_description: "形象描述",
        description: "個人描述",
        belief: "思念與信念",
        sig_people: "重要之人",
        mean_location: "意義非凡之地",
        treasured_possession: "寶貴之物",
        trait: "特點",
        class_feature: "職業特徵",
        class_point: "職業：",
        interest_point: "興趣：",
        skill_name: "名稱",
        skill_default: "初始",
        skill_interest: "興趣",
        skill_custom: "自定義",
        skill_class: "職業",
        skill_grow: "成長",
        skill_total: "總值",
        "Accounting": "會計",
        "Anthropology": "人類學",
        "Appraise": "估價",
        "Archaeology": "考古學",
        "Charm": "魅惑",
        "Climb": "攀爬",
        "Computer Use": "電腦使用",
        "Credit Rating": "信用評級",
        "Cthulhu Mythos": "克蘇魯神話",
        "Disguise": "喬裝",
        "Dodge": "閃避",
        "Drive Auto": "自動車駕駛",
        "Electrical Repair": "電器維修",
        "Electronics": "電子學",
        "Fast-Talk": "話術",
        "First Aid": "急救",
        "History": "歷史",
        "Intimidate": "威嚇",
        "Jump": "跳躍",
        "Law": "法律",
        "Library Use": "圖書館使用",
        "Listen": "聆聽",
        "Locksmith": "鎖匠",
        "Mechanical Repair": "機器維修",
        "Medicine": "醫學",
        "Natural World": "自然學",
        "Navigate": "導航",
        "Occult": "神祕學",
        "Operate Heavy Machinery": "操縱重型機器",
        "Persuade": "說服",
        "Psychoanalysis": "心理分析",
        "Psychology": "心理學",
        "Ride": "騎術",
        "Sleight of Hand": "巧手",
        "Spot Hidden": "偵查",
        "Stealth": "隱密行動",
        "Swim": "游泳",
        "Throw": "投擲",
        "Track": "追蹤",
        "Animal Handling": "動物馴養",
        "Artillery": "炮術",
        "Demolitions": "爆破",
        "Diving": "潛水",
        "Hypnosis": "催眠",
        "Read Lips": "讀唇",
        "Pilot": "專業駕駛",
        "Survival": "求生",
        "Art and Craft": "藝術與工藝",
        "Fighting": "鬥毆",
        "Firearms": "火器",
        "Language (Other)": "他國語言",
        "Language (Own)": "母語",
        "Science": "科學",
        "Lore": "偏門學問",
    },
    dnd5e: {
        char_info: "冒險者基本資料：",
        char_status: "冒險者狀態：",
        char_image: "冒險者形象：",
        char_skill: "冒險者技能：",
        char_equip: "冒險者裝備：",
        char_appearance: "冒險者外觀：",
        char_trait: "冒險者特質：",
        char_background: "冒險者背景：",
        hint: {
            class: {
                bard: "吟遊詩人(Bard)",
                barbarian: "野蠻人(Barbarian)",
                cleric: "牧師(Cleric)",
                druid: "德魯伊(Druid)",
                fighter: "戰士(Fighter)",
                monk: "武僧(Monk)",
                paladin: "聖騎士(Paladin)",
                ranger: "遊俠(Ranger)",
                rogue: "盜賊(Rogue)",
                sorcerer: "術士(Sorcerer)",
                warlock: "契術師(Warlock)",
                wizard: "法師(Wizard)"
            },
            faction: {
                lawful_good: "守序善良",
                neutral_good: "中立善良",
                chaotic_good: "混亂善良",
                lawful_neutral: "守序中立",
                true_neutral: "絕對中立",
                chaotic_neutral: "混亂中立",
                lawful_evil: "守序邪惡",
                neutral_evil: "中立邪惡",
                chaotic_evil: "混亂邪惡"
            }
        },
        str: "力量",
        dex: "敏捷",
        con: "體魄",
        int: "智慧",
        wis: "感知",
        cha: "魅力",
        level: "等級",
        exp: "經驗",
        faction: "陣營",
        race: "種族",
        class: "職業",
        background: "背景",
        armorValue: "護甲值",
        initiative: "先攻值",
        speed: "速度",
        hp: "臨時生命/生命值/最大生命",
        hit_dice: "生命骰/總生命骰",
        success: "成功",
        fail: "失敗",
        death_save: "死亡豁免",
        attack_spellcast: "攻擊&法術",
        attack: "攻擊",
        spellcast: "法術",
        passive_wisdom: "被動感知",
        inspiration: "激勵值",
        pro: "熟練加成",
        savings: "豁免檢定",
        skills: "技能",
        equipment: "裝備",
        treasure: "寶物",
        language: "其他專長&語言",
        height: "身高",
        skin: "膚色",
        pupil: "瞳孔顏色",
        weight: "體重",
        hair: "髮色",
        trait: "特徵",
        otherTrait: "其他特徵&特性",
        personality: "性格",
        ideals: "理念",
        bonds: "關係",
        flaws: "缺點",
        backstory: "背景歷史",
        alignment: "同盟&組織",
        spell_class: "法術職業",
        spell_ability: "法術施展能力",
        spell_save: "法術豁免值",
        spell_bonus: "法術攻擊加值",
        cantrips: "小法術",
        spell_total: "總數",
        spell_expanded: "用量",
        list_append: "增加",
        Acrobatics: "特技動作",
        "Animal Handling": "馴服動物",
        Arcana: "奧術",
        Athletics: "運動",
        Deception: "欺瞞",
        History: "歷史",
        Insight: "察言觀色",
        Intimidation: "恐嚇",
        Investigation: "調查",
        Medicine: "醫術",
        Nature: "自然",
        Perception: "觀察",
        Performance: "表演",
        Persuasion: "說服",
        Religion: "宗教",
        "Sleight of Hand": "扒手",
        Stealth: "隱藏",
        Survival: "求生",


    },
    coc6th: {
        char_info: "調查員基本資料：",
        char_status: "調查員狀態：",
        char_equip: "調查員裝備：",
        char_image: "調查員形象：",
        char_background: "調查員背景：",
        char_data: "調查員資料：",
        char_skill: "調查員技能：",
        magic_tomes: "魔法與傳聞：",
        class: "職業",
        degree: "學歷",
        birthplace: "出生地",
        residence: "現居地",
        db_build: "傷害加成(DB)",
        idea: "靈感(IDEA)",
        know: "知識(KNOW)",
        luk: "幸運(LUCK)",
        mental: "瘋狂程度",
        insanity: "精神疾病",
        money: "資產",
        weapon: "武器",
        equip: "攜帶物品",
        injuries: "創傷與傷口",
        scars: "傷痕與傷疤",
        encounter: "第三類接觸",
        tomes: "閱讀過的典籍",
        magic: "已知咒文與神器",
        role_description: "形象描述",
        description: "背景故事",
        family: "家人與朋友",
        trait: "特點",
        class_point: "職業：",
        interest_point: "興趣：",
        skill_name: "名稱",
        skill_default: "初始",
        skill_interest: "興趣",
        skill_custom: "自定義",
        skill_class: "職業",
        skill_grow: "成長",
        skill_total: "總值",
        "Accounting": "會計",
        "Anthropology": "人類學",
        "Archaeology": "考古學",
        "Astronomy": "天文學",
        "Bargain": "議價",
        "Biology": "生物學",
        "Chemistry": "化學",
        "Climb": "攀爬",
        "Computer Use": "電腦使用",
        "Conceal": "藏匿",
        "Credit Rating": "信用評級",
        "Cthulhu Mythos": "克蘇魯神話",
        "Disguise": "喬裝",
        "Dodge": "閃避",
        "Drive Auto": "自動車駕駛",
        "Electrical Repair": "電器維修",
        "Electronics": "電子學",
        "Fast-Talk": "話術",
        "First Aid": "急救",
        "Firearm":"火器",
        "Geology": "地理學",
        "History": "歷史",
        "Jump": "跳躍",
        "Law": "法律",
        "Library Use": "圖書館使用",
        "Listen": "聆聽",
        "Locksmith": "鎖匠",
        "Mechanical Repair": "機器維修",
        "Medicine": "醫學",
        "Natural World": "自然學",
        "Navigate": "導航",
        "Occult": "神祕學",
        "Operate Heavy Machinery": "操縱重型機器",
        "Persuade": "說服",
        "Pharmacy": "藥學",
        "Photography": "攝影",
        "Physics": "物理",
        "Psychoanalysis": "心理分析",
        "Psychology": "心理學",
        "Ride": "騎術",
        "Sleight of Hand": "巧手",
        "Spot Hidden": "偵查",
        "Stealth": "隱密行動",
        "Swim": "游泳",
        "Throw": "投擲",
        "Track": "追蹤",
        "Handgun": "手槍",
        "Machine Gun": "機關槍",
        "Rifle": "步槍",
        "Shotgun": "霰彈槍",
        "SMG": "衝鋒槍",
        "Hypnosis": "催眠",
        "Read Lips": "讀唇",
        "Pilot": "專業駕駛",
        "Pilot(Car)": "駕駛汽車",
        "Pilot(Carriage)": "駕駛馬車",
        "Art and Craft": "藝術與工藝",
        "Fighting": "鬥毆",
        "Weapon": "武器",
        "Language (Other)": "他國語言",
        "Language (Own)": "母語",
        "Science": "科學",
        "Lore": "偏門學問",
        "Fist": "拳頭",
        "Kick": "踢擊",
        "Grapple": "擒拿",
        "Head": "頭槌"
    },

}
