export default {
    lang: {
        zh_tw: "Traditional Chinese",
        zh_cn: "Simplified Chinese",
        en_us: "English"
    },
    yes: "yes",
    no: "no",
    name: "Name",
    delete: "Delete",
    player_name: "Player",
    age: "Age",
    sex: "Gender",
    none: "None",
    select_image: "Select Image",
    upload_image: "Upload Image",
    cancel_image: "Cancel Image",
    info: "Info",
    background: "Background",
    skill: "Skill",
    skill_equip: "Skill & Equipment",
    weapon_equip: "Weapon & Equipment",
    spell: "Spell",
    note: "Note",
    option: "Option",
    viewPermission:"View Permission",
    allPeople:"All",
    sessionPlayer:"Session Players",
    sessionGM:"GM only",
    copyLink:"Copy URL",
    downloadJSON:"Download as json",
    coc7th: {
        char_info: "Investigator Info:",
        char_status: "Investigator Status:",
        char_equip: "Investigator Equipment：",
        char_image: "Investigator Image:",
        char_background: "Investigator Background:",
        char_experience: "Investigator Experience:",
        char_skill: "Investigator Skill",
        class: "Occupation",
        birthplace: "Birthplace",
        residence: "Residence",
        db_build: "DB & Build",
        mov: "MOV",
        injured_status: "Injured Status",
        injured1: "Serious Injured",
        injured2: "Dying",
        injured3: "Temp Stable",
        insane_status: "Insane Status",
        insane1: "Temp Insane",
        insane2: "Indef Insane",
        insane3: "Latency Insane",
        money: "Cash",
        weapon: "Weapon",
        equip: "Equipment",
        injuries: "Injuries & Scars",
        encounter: "Encounters With Strange Entities",
        mania: "Phobias & Manias",
        magic: "Arcane Tomes, Spells & Artifacts",
        myth: "Myth Believer",
        role_description: "Role Description",
        description: "Personal Description",
        belief: "Ideology/Beliefs",
        sig_people: "Significant People",
        mean_location: "Meaningful Location",
        treasured_possession: "Treasured Possessions",
        trait: "Traits",
        class_feature: "Class Feature",
        class_point: "Class:",
        interest_point: "Interest：",
        skill_name: "Name",
        skill_default: "Initial",
        skill_interest: "Interest",
        skill_custom: "Custom",
        skill_class: "Class",
        skill_grow: "Grow",
        skill_total: "Total",
        "Accounting": "Accounting",
        "Anthropology": "Anthropology",
        "Appraise": "Appraise",
        "Archaeology": "Archaeology",
        "Charm": "Charm",
        "Climb": "Climb",
        "Computer Use": "Computer Use",
        "Credit Rating": "Credit Rating",
        "Cthulhu Mythos": "Cthulhu Mythos",
        "Disguise": "Disguise",
        "Dodge": "Dodge",
        "Drive Auto": "Drive Auto",
        "Electrical Repair": "Electrical Repair",
        "Electronics": "Electronics",
        "Fast-Talk": "Fast-Talk",
        "First Aid": "First Aid",
        "History": "History",
        "Intimidate": "Intimidate",
        "Jump": "Jump",
        "Law": "Law",
        "Library Use": "Library Use",
        "Listen": "Listen",
        "Locksmith": "Locksmith",
        "Mechanical Repair": "Mechanical Repair",
        "Medicine": "Medicine",
        "Natural World": "Natural World",
        "Navigate": "Navigate",
        "Occult": "Occult",
        "Operate Heavy Machinery": "Operate Heavy Machinery",
        "Persuade": "Persuade",
        "Psychoanalysis": "Psychoanalysis",
        "Psychology": "Psychology",
        "Ride": "Ride",
        "Sleight of Hand": "Sleight of Hand",
        "Spot Hidden": "Spot Hidden",
        "Stealth": "Stealth",
        "Swim": "Swim",
        "Throw": "Throw",
        "Track": "Track",
        "Animal Handling": "Animal Handling",
        "Artillery": "Artillery",
        "Demolitions": "Demolitions",
        "Diving": "Diving",
        "Hypnosis": "Hypnosis",
        "Read Lips": "Read Lips",
        "Pilot": "Pilot",
        "Survival": "Survival",
        "Art and Craft": "Art and Craft",
        "Fighting": "Fighting",
        "Firearms": "Firearms",
        "Language (Other)": "Language (Other)",
        "Language (Own)": "Language (Own)",
        "Science": "Science",
        "Lore": "Lore"
    },
    coc6th: {
        char_info: "Investigator Info:",
        char_status: "Investigator Status:",
        char_equip: "Investigator Equipment：",
        char_image: "Investigator Image:",
        char_background: "Investigator Background:",
        char_data: "Investigator Data:",
        char_skill: "Investigator Skill",
        magic_tomes: "Magic & Tones:",
        class: "Occupation",
        degree: "Colleges, Degrees",
        birthplace: "Birthplace",
        residence: "Residence",
        db_build: "DB",
        idea: "IDEA",
        know: "KNOW",
        luk: "LUCK",
        mental: "Mental Disorder",
        insanity: "Episodes of Insanity",
        money: "Estate",
        weapon: "Weapon",
        equip: "Equipment",
        injuries: "Wounds & Injuries",
        scars: "Marks & Scars",
        encounter: "Encounters With Strange Entities",
        mania: "Phobias & Manias",
        tomes: "Mythos Tomes Read",
        magic: "Magical Artifacts/Spells Known",
        myth: "Myth Believer",
        role_description: "Personal Description",
        description: "Investigator History",
        belief: "Ideology/Beliefs",
        sig_people: "Significant People",
        mean_location: "Meaningful Location",
        treasured_possession: "Treasured Possessions",
        trait: "Traits",
        class_point: "Class:",
        interest_point: "Interest:",
        skill_name: "Name",
        skill_default: "Initial",
        skill_interest: "Interest",
        skill_custom: "Custom",
        skill_class: "Class",
        skill_grow: "Grow",
        skill_total: "Total",
        "Accounting": "Accounting",
        "Anthropology": "Anthropology",
        "Archaeology": "Archaeology",
        "Astronomy": "Astronomy",
        "Bargain": "Bargain",
        "Biology": "Biology",
        "Chemistry": "Chemistry",
        "Climb": "Climb",
        "Computer Use": "Computer Use",
        "Conceal": "Conceal",
        "Credit Rating": "Credit Rating",
        "Cthulhu Mythos": "Cthulhu Mythos",
        "Disguise": "Disguise",
        "Dodge": "Dodge",
        "Drive Auto": "Drive Auto",
        "Electrical Repair": "Electrical Repair",
        "Electronics": "Electronics",
        "Fast-Talk": "Fast-Talk",
        "First Aid": "First Aid",
        "Firearm": "Firearm",
        "Geology": "Geology",
        "History": "History",
        "Jump": "Jump",
        "Law": "Law",
        "Library Use": "Library Use",
        "Listen": "Listen",
        "Locksmith": "Locksmith",
        "Mechanical Repair": "Mechanical Repair",
        "Medicine": "Medicine",
        "Natural World": "Natural World",
        "Navigate": "Navigate",
        "Occult": "Occult",
        "Operate Heavy Machinery": "Operate Heavy Machinery",
        "Persuade": "Persuade",
        "Pharmacy": "Pharmacy",
        "Photography": "Photography",
        "Physics": "Physics",
        "Psychoanalysis": "Psychoanalysis",
        "Psychology": "Psychology",
        "Ride": "Ride",
        "Sleight of Hand": "Sleight of Hand",
        "Spot Hidden": "Spot Hidden",
        "Stealth": "Stealth",
        "Swim": "Swim",
        "Throw": "Throw",
        "Track": "Track",
        "Handgun": "Handgun",
        "Machine Gun": "Machine Gun",
        "Rifle": "Rifle",
        "Shotgun": "Shotgun",
        "SMG": "SMG",
        "Hypnosis": "Hypnosis",
        "Read Lips": "Read Lips",
        "Pilot": "Pilot",
        "Pilot(Car)": "Pilot(Car)",
        "Pilot(Carriage)": "Pilot(Carriage)",
        "Art and Craft": "Art and Craft",
        "Fighting": "Fighting",
        "Weapon": "Weapon",
        "Language (Other)": "Language (Other)",
        "Language (Own)": "Language (Own)",
        "Science": "Science",
        "Lore": "Lore",
        "Fist": "Fist",
        "Kick": "Kick",
        "Grapple": "Grapple",
        "Head": "Head"
    },
    dnd5e: {
        char_info: "Character Info:",
        char_status: "Character Status:",
        char_image: "Character Image:",
        char_skill: "Character Skill:",
        char_equip: "Character Equipment:",
        char_appearance: "Character Appearance:",
        char_trait: "Character Trait:",
        char_background: "Character Background",
        hint: {
            class: {
                bard: "Bard",
                barbarian: "Barbarian",
                cleric: "Cleric",
                druid: "Druid",
                fighter: "Fighter",
                monk: "Monk",
                paladin: "Paladin",
                ranger: "Ranger",
                rogue: "Rogue",
                sorcerer: "Sorcerer",
                warlock: "Warlock",
                wizard: "Wizard"
            },
            faction: {
                lawful_good: "Lawful Good",
                neutral_good: "Neutral Good",
                chaotic_good: "Chaotic Good",
                lawful_neutral: "Lawful ",
                true_neutral: "True Neutral",
                chaotic_neutral: "Chaotic Neutral",
                lawful_evil: "Lawful Evil",
                neutral_evil: "Neutral Evil",
                chaotic_evil: "Chaotic Evil"
            }
        },
        str: "Strength",
        dex: "Dexterity",
        con: "Constitution",
        int: "Intelligence",
        wis: "Wisdom",
        cha: "Charisma",
        level: "Level",
        exp: "Exp Points",
        class: "Class",
        faction: "Alignment",
        race: "Race",
        background: "Background",
        armorValue: "Armor Class",
        initiative: "Initiative",
        speed: "Speed",
        hp: "Temp HP/Current HP/Max HP",
        hit_dice: "Hit Dice/Total",
        success: "Success",
        fail: "Fail",
        death_save: "Death Save",
        attack_spellcast: "Attacks & Spellcasting",
        attack: "Attack",
        spellcast: "Spellcating",
        passive_wisdom: "Passive Wisdom",
        inspiration: "Inspiration",
        pro: "Proficiency Bonus",
        savings: "Saving Throws",
        skills: "Skills",
        equipment: "Equipment",
        treasure: "Treasure",
        language: "Other Proficiency & Languages",
        height: "Height",
        skin: "Skin",
        pupil: "Eyes",
        weight: "Weight",
        hair: "Hair",
        trait: "Features & Traits",
        otherTrait: "Additional Features & Traits",
        personality: "Personality",
        ideals: "Ideals",
        bonds: "Bonds",
        flaws: "Flaws",
        backstory: "Backstory",
        alignment: "Allies & Organizations",
        spell_class: "Spellcasting Class",
        spell_ability: "Spellcating Ability",
        spell_save: "Spell Save DC",
        spell_bonus: "Spell Attack Bonus",
        cantrips: "Cantrips",
        spell_total: "Total",
        spell_expanded: "Expanded",
        list_append: "Append",
        Acrobatics: "Acrobatics",
        "Animal Handling": "Animal Handling",
        Arcana: "Arcana",
        Athletics: "Athletics",
        Deception: "Deception",
        History: "History",
        Insight: "Insight",
        Intimidation: "Intimidation",
        Investigation: "Investigation",
        Medicine: "Medicine",
        Nature: "Nature",
        Perception: "Perception",
        Performance: "Performance",
        Persuasion: "Persuasion",
        Religion: "Religion",
        "Sleight of Hand": "Sleight of Hand",
        Stealth: "Stealth",
        Survival: "Survival",


    }


}
